import React from "react";
import dictionary from "../../dictionary";
import functions from "../../functions";
import ajax from "../../ajax";
import ActivityLoader from "../Common/ActivityLoader";
import AlertError from "../Common/AlertError";
import TextInput from "../Common/TextInput";
import AlertSuccess from "../Common/AlertSuccess";

class PopAddPrice extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    price: "",
    info: "",
    loading: false,
  };

  onChangePrice = (event) => {
    this.setState({ price: event.target.value });
  };

  onConfirm = (event) => {
    event.preventDefault();
    this.setState(
      {
        info: "",
      },
      async () => {
        const { price } = this.state;

        if (price.length > 0) {
          const server_response = await ajax.addBettingPrice(price);
          if ((server_response.status = "OK")) {
            this.setState({
              info: <AlertSuccess message="Price Successfully Added" />,
            });
          } else {
            this.setState({
              info: <AlertError message={"Failed To Add Price"} />,
            });
          }
        } else {
          this.setState({
            info: <AlertError message={dictionary._completeFields} />,
          });
        }
      }
    );
  };

  render() {
    return (
      <>
        <div className="modal" id="modal_add_price">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-demo shadow">
              <div className="modal-header">
                <h6 className="modal-title">Add Price</h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.info}
                <form method="post" onSubmit={this.onConfirm}>
                  <TextInput
                    label="Enter Price"
                    type="number"
                    onChange={() => this.onChangePrice}
                    value={this.state.price}
                  />
                </form>

                {/* <p><i>Cash withdrawn fr.</i></p> */}
              </div>
              {!this.state.loading && (
                <div className="modal-footer">
                  <button
                    className="btn ripple btn-success"
                    type="button"
                    onClick={this.onConfirm}
                  >
                    Add Price
                  </button>
                  <button
                    className="btn ripple btn-secondary"
                    type="button"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              )}

              {this.state.loading && (
                <div className="modal-footer">
                  <ActivityLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PopAddPrice;
