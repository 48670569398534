import React from "react";
import { Link } from "react-router-dom";
import ajax from "../../ajax";
import functions from "../../functions";

class LeftSideBar extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    sideBarInitiated: true,
    activeLocationPath: window.location.pathname,
    activeRoot: "",
  };

  componentDidMount() {
    const pathSections = this.state.activeLocationPath.split("/");
    this.setState({
      activeRoot: "/" + pathSections[1],
    });
  }

  toggleMenu = (e) => {
    e.preventDefault();
    if (e.target.parentElement.nodeName === "A") {
      //console.log(e.target.parentElement.offsetParent)

      functions.removeClassFromElementSiblings(
        e.target.parentElement.offsetParent,
        "show"
      );
      e.target.parentElement.offsetParent.classList.toggle("show");
    } else {
      functions.removeClassFromElementSiblings(e.target.parentElement, "show");
      e.target.parentElement.classList.toggle("show");
    }
  };

  toggleSideBar = () => {
    let element = document.getElementsByTagName("body");
    if (window.matchMedia("(min-width: 768px)").matches) {
      element[0].classList.toggle("main-sidebar-hide");
    } else {
      element[0].classList.toggle("main-sidebar-show");
    }
  };

  openSideBar = () => {
    const controlElement = document.getElementsByClassName("main-sidebar-hide");
    if (controlElement.length > 0) {
      let element = document.getElementsByTagName("body");
      element[0].classList.add("main-sidebar-open");
      this.setState({
        sideBarInitiated: true,
      });
    }
  };

  closeSideBar = () => {
    const controlElement = document.getElementsByClassName("main-sidebar-hide");
    if (controlElement.length > 0) {
      let element = document.getElementsByTagName("body");
      element[0].classList.remove("main-sidebar-open");
      this.setState({
        sideBarInitiated: false,
      });
    }
  };

  logos = () => {
    return (
      <a replace className="main-logo" to="#">
        <img
          src={process.env.PUBLIC_URL + "../../images/logo.png"}
          className="header-brand-img desktop-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          className="header-brand-img icon-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/logo.png"}
          className="header-brand-img desktop-logo theme-logo"
          alt="logo"
        />
        <img
          src={process.env.PUBLIC_URL + "../../images/qp_b.png"}
          className="header-brand-img icon-logo theme-logo"
          alt="logo"
        />
      </a>
    );
  };

  render() {
    const accessInfo = functions.readPermissions();
    const activeRoot = this.state.activeRoot;
    const path = this.state.activeLocationPath;

    return (
      <div
        className="main-sidebar main-sidebar-sticky side-menu"
        onMouseEnter={this.openSideBar}
        onMouseLeave={this.closeSideBar}
      >
        <div className="sidemenu-logo">
          <div className="touch-close-menu">
            <i
              className="fas fa-align-left custom-menu-icon"
              onClick={this.toggleSideBar}
            ></i>
          </div>
          <this.logos />
        </div>

        <div className="main-sidebar-body">
          <ul className="nav">
            <li className="nav-label">Dashboard</li>

            {functions.findInObject(accessInfo, "WALLET0001-2") && (
              <li
                className={`nav-item show ${
                  activeRoot === "/betting" && "active"
                }`}
              >
                <Link className="nav-link" to="/betting">
                  <i className="fe fe-award"></i>
                  <span className="sidemenu-label">Betting Tips</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default LeftSideBar;
