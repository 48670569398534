import React from "react";
import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./Pages/Login";
import functions from "./functions";
// import Settings from "./Pages/Settings";

import SetPassword from "./Pages/SetPassword";
import NoPermission from "./Pages/NoPermission";
import DeviceLog from "./Pages/Access/DeviceLog";

import UserManagement from "./Pages/Access/UserManagement";
import Activity from "./Pages/Access/Activity";
/////////////henry/////////////////////////////////////////////////////////////////////

import BettingDashboard from "./Pages/Betting/BettingDashboard";

class App extends React.Component {
  state = {
    screen: "Login",
    loggedIn: true,
    accessInfo: functions.readPermissions(),
  };

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    //const cookies = new Cookies();
    //if(!cookies.get('quickpost@user'))
    if (!window.localStorage.getItem("quickpost@user")) {
      this.setState({
        loggedIn: false,
      });
    } else {
      this.setState({
        loggedIn: true,
      });
    }
  }

  render() {
    const secure = functions.checkSecureAccount();
    return (
      <div id="app_container" onClick={functions.closeHeaderDropdowns}>
        <Router forceRefresh={false}>
          {!this.state.loggedIn && <Redirect to="/login" />}
          {secure === "0" && <Redirect to="/security/set-password" />}
          <Switch>
            {!this.state.loggedIn && <Route exact path="/" component={Login} />}
            {this.state.loggedIn && (
              <Route exact path="/" component={BettingDashboard} />
            )}

            <Route exact path="/login" component={Login} />

            {/* <Route exact path="/settings" component={Settings} /> */}
            <Route
              exact
              path="/security/set-password"
              component={SetPassword}
            />
            <Route exact path="/activity/device_logs" component={DeviceLog} />

            <Route
              exact
              path="/users/user_management"
              component={UserManagement}
            />

            <Route exact path="/activity" component={Activity} />

            <Route exact path="/betting" component={BettingDashboard} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
